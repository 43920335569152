export default function () {
	return {
		init() {
			const sidebar = this.$el.querySelector('[data-lenis-prevent]')
			const mainContent = document.querySelector('.main-content')

			if (mainContent) {
				mainContent.addEventListener('wheel', (event) => {
					event.preventDefault()
				})
			}
		},
	}
}
