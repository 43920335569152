import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const initTimelineAnimations = () => {
	const timelineItems = document.querySelectorAll('.timeline__item')
	const progress = document.querySelector('.timeline__progress')
	const progressBar = document.querySelector('.timeline__progress__bar')

	if (!timelineItems || !progress || !progressBar) {
		return
	}

	gsap.set(progressBar, {
		scaleY: 0,
		transformOrigin: 'top center',
	})

	const progressTl = gsap.timeline({
		scrollTrigger: {
			trigger: '.timeline',
			start: 'top center',
			end: 'bottom center',
			scrub: 1,
			markers: false,
			onUpdate: () => {
				progressBar.style.transform = `scaleY(${progressTl.progress()})`
			},
		},
	})

	// First, make sure all elements are visible but with initial transformed states
	timelineItems.forEach((item, index) => {
		const image = item.querySelector('.timeline__image img')
		const content = item.querySelector('.timeline__content')
		const title = content.querySelector('.timeline__title span')
		const description = content.querySelector('.timeline__description')
		const dot = item.querySelector('.timeline__dot')
		const divider = item.querySelector('.timeline__divider')
		const isOdd = index % 2 === 1

		// Set initial states - make sure elements are visible but transformed
		gsap.set(image, {
			autoAlpha: 0,
			y: 100,
		})

		gsap.set([content, description], {
			autoAlpha: 0,
			y: 100,
		})

		gsap.set(title, {
			y: 100,
		})

		gsap.set(divider, {
			width: 0,
		})

		if (dot) {
			gsap.set(dot, {
				autoAlpha: 1,
				scale: 0,
			})
		}

		// Create animation for each item
		gsap
			.timeline({
				scrollTrigger: {
					trigger: item,
					start: 'top 80%', // Trigger earlier
					end: 'bottom 20%',
					markers: false, // Keep markers on for debugging
					toggleActions: 'play none none reverse',
					scrub: 1,
				},
			})
			.to(
				image,
				{
					duration: 1,
					y: 0,
					autoAlpha: 1,
					ease: 'power2.out',
				},
				'-=2',
			)
			.to(
				divider,
				{
					duration: 1,
					width: '100%',
					ease: 'power2.out',
				},
				'-=2',
			)
			.to(
				[content, title, description],
				{
					duration: 1,
					y: 0,
					autoAlpha: 1,
					ease: 'power2.out',
					stagger: 0.25,
				},
				'-=2',
			)
			.to(
				dot,
				{
					duration: 0.4,
					scale: 1,
					autoAlpha: 1,
					ease: 'power2.out',
				},
				'-=2',
			)
	})
}

// Initialize animations
if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initTimelineAnimations)
} else {
	initTimelineAnimations()
}

// Clean up function if needed
const cleanup = () => {
	ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
	gsap.killTweensOf('*')
}

export { cleanup, initTimelineAnimations }
