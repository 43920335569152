import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

export function initializeFancybox() {
	Fancybox.bind('[data-fancybox]', {
		Carousel: {
			infinite: true,
			transition: 'slide',
		},
	})
}
