import Headroom from 'headroom.js'

const HEADROOM_OPTIONS = {
	offset: 0,
	tolerance: 0,
	classes: {
		initial: 'headroom',
		pinned: 'headroom--pinned',
		unpinned: 'headroom--unpinned',
	},
}

export function initializeHeadroom() {
	const header = document.querySelector('.header')
	if (header) {
		header.classList.remove('hidden')
		// header.classList.remove('-translate-y-full');
		const headroom = new Headroom(header, HEADROOM_OPTIONS)
		headroom.init()
		console.log('Headroom initialized')
	}
}
