import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(SplitText)

const defaultOptions = {
	duration: 1,
	delay: 0,
	ease: 'power3.out',
	splitText: false,
	faster: false,
	stagger: 0.2,
}

// Keep track of preloader-aware elements that have been animated
const preloaderAnimatedElements = new WeakSet()

export default () => ({
	animate(target, type, options = {}) {
		// Only check preloaderAnimatedElements for preloader-aware animations
		if (
			target.hasAttribute('x-init') &&
			target.getAttribute('x-init').includes('initPreloaderAwareAnimation') &&
			preloaderAnimatedElements.has(target)
		) {
			return
		}

		const animationOptions = { ...defaultOptions, ...options }

		// Only mark as animated for preloader-aware animations
		if (target.hasAttribute('x-init') && target.getAttribute('x-init').includes('initPreloaderAwareAnimation')) {
			preloaderAnimatedElements.add(target)
		}

		switch (type) {
			case 'fadeIn':
				return this.fadeIn(target, animationOptions)
			case 'fadeOut':
				return this.fadeOut(target, animationOptions)
			case 'slideUp':
				return this.slideUp(target, animationOptions)
			case 'slideDown':
				return this.slideDown(target, animationOptions)
			case 'fadeInStagger':
				return this.fadeInStagger(target, animationOptions)
			case 'slideUpStagger':
				return this.slideUpStagger(target, animationOptions)
			case 'fadeRight':
				return this.fadeRight(target, animationOptions)
			default:
				console.warn(`Animation type '${type}' not recognized.`)
		}
	},

	fadeIn(target, options) {
		gsap.fromTo(
			target,
			{ autoAlpha: 0, y: 24 },
			{
				autoAlpha: 1,
				y: 0,
				ease: options.ease,
				duration: options.duration,
				delay: options.delay,
				splitText: options.splitText,
			},
		)
	},

	fadeOut(target, options) {
		gsap.fromTo(
			target,
			{ autoAlpha: 1, y: 0 },
			{
				autoAlpha: 0,
				y: 24,
				ease: options.ease,
				duration: options.duration,
				delay: options.delay,
			},
		)
	},

	createMask(target) {
		const mask = document.createElement('div')
		mask.classList.add('mask')
		target.parentNode.insertBefore(mask, target)
		mask.appendChild(target)
	},

	slideUp(target, options) {
		gsap.fromTo(
			target,
			{ autoAlpha: 0, y: 24 },
			{
				autoAlpha: 1,
				y: 0,
				duration: options.duration,
				ease: options.ease,
				delay: options.delay,
			},
		)
	},

	slideDown(target, options) {
		gsap.fromTo(
			target,
			{ autoAlpha: 0, y: 0 },
			{
				autoAlpha: 1,
				y: -24,
				duration: options.duration,
				ease: options.ease,
				delay: options.delay,
			},
		)
	},

	slide(target, options, direction) {
		const elements = target.querySelectorAll('*')
		const splitText = options.splitText ? new SplitText(elements, { type: 'lines', linesClass: 'line' }) : null

		const lines = splitText ? splitText.lines : [target]
		const yPosition = direction === 'up' ? 24 : -24

		gsap.fromTo(
			lines,
			{ autoAlpha: 0, y: yPosition },
			{
				y: 0,
				autoAlpha: 1,
				duration: options.duration,
				ease: options.ease,
				stagger: options.stagger,
				delay: options.delay,
			},
		)
	},

	hide(target, options = {}) {
		// Reset animation state only for preloader-aware animations
		if (target.hasAttribute('x-init') && target.getAttribute('x-init').includes('initPreloaderAwareAnimation')) {
			preloaderAnimatedElements.delete(target)
		}

		// Set initial state immediately with CSS
		target.style.opacity = '0'
		target.style.visibility = 'hidden'

		// Then use GSAP to ensure proper animation setup
		gsap.set(target, {
			opacity: 0,
			y: 0,
			visibility: 'hidden',
			delay: options.delay ?? defaultOptions.delay,
		})
	},

	initPreloaderAwareAnimation(target) {
		// Skip if already animated (only for preloader animations)
		if (preloaderAnimatedElements.has(target)) {
			return
		}

		// Set initial state
		this.hide(target)

		// Function to check if preloader is done
		const isPreloaderDone = () =>
			!document.getElementById('preloader') || document.getElementById('preloader').style.display === 'none'

		// Create a timeline
		const tl = gsap.timeline()
		tl.fromTo(
			target,
			{ autoAlpha: 0, y: 24 },
			{
				autoAlpha: 1,
				y: 0,
				duration: 1,
				ease: 'power3.out',
				onComplete: () => {
					// Mark as animated (only for preloader animations)
					preloaderAnimatedElements.add(target)
				},
			},
		)

		// If preloader is already gone, play immediately
		if (isPreloaderDone()) {
			tl.play()
		} else {
			// Otherwise, wait for preloader to be done
			const checkPreloader = () => {
				if (isPreloaderDone()) {
					tl.play()
				} else {
					requestAnimationFrame(checkPreloader)
				}
			}
			checkPreloader()
		}
	},

	fadeInStagger(target, options) {
		// If target has no children, split the text into lines
		if (target.children.length === 0) {
			// Add overflow hidden to prevent flashing
			target.style.overflow = 'hidden'

			// Split text into lines
			const splitText = new SplitText(target, {
				type: 'lines',
				linesClass: 'split-line',
			})

			// Add wrapper class to each line
			splitText.lines.forEach((line) => {
				// Create wrapper div
				const wrapper = document.createElement('div')
				wrapper.style.overflow = 'hidden'
				wrapper.style.paddingBottom = '0.1em' // Add slight padding between lines

				// Wrap the line
				line.parentNode.insertBefore(wrapper, line)
				wrapper.appendChild(line)

				// Add styles to the line
				// line.style.display = 'block'
				// line.style.lineHeight = '1.2'
			})

			// Hide all lines initially
			gsap.set(splitText.lines, { y: 24, autoAlpha: 0 })

			// Make sure target is visible but transparent
			gsap.set(target, { visibility: 'visible', opacity: 0 })

			// Create timeline for better control
			const tl = gsap.timeline()

			// Fade in target first
			tl.to(target, {
				opacity: 1,
				duration: 0.01,
			})

			// Then animate lines
			tl.to(splitText.lines, {
				y: 0,
				autoAlpha: 1,
				duration: options.duration,
				ease: options.ease,
				stagger: options.stagger,
				delay: options.delay,
			})
		} else {
			// Handle child elements as before
			const children = [...target.children]
			gsap.set(children, { autoAlpha: 0, y: 24 })

			// Make sure target is visible but transparent
			gsap.set(target, { visibility: 'visible', opacity: 0 })

			// Create timeline for better control
			const tl = gsap.timeline()

			// Fade in target first
			tl.to(target, {
				opacity: 1,
				duration: 0.01,
			})

			// Then animate children
			tl.to(children, {
				autoAlpha: 1,
				y: 0,
				duration: options.duration,
				ease: options.ease,
				stagger: options.stagger,
				delay: options.delay,
			})
		}
	},

	slideUpStagger(target, options) {
		// If target has no children, split the text into lines
		if (target.children.length === 0) {
			// Add overflow hidden to prevent flashing
			target.style.overflow = 'hidden'

			// Split text into lines
			const splitText = new SplitText(target, {
				type: 'lines',
				linesClass: 'split-line',
			})

			// Add wrapper class to each line
			splitText.lines.forEach((line) => {
				// Create wrapper div
				const wrapper = document.createElement('div')
				wrapper.style.overflow = 'hidden'
				wrapper.style.paddingBottom = '0.1em' // Add slight padding between lines

				// Wrap the line
				line.parentNode.insertBefore(wrapper, line)
				wrapper.appendChild(line)

				// Add styles to the line
				// line.style.display = 'block'
				// line.style.lineHeight = '1.2'
			})

			// Hide all lines initially
			gsap.set(splitText.lines, { y: 24, autoAlpha: 0 })

			// Make sure target is visible but transparent
			gsap.set(target, { visibility: 'visible', opacity: 0 })

			// Create timeline for better control
			const tl = gsap.timeline()

			// Fade in target first
			tl.to(target, {
				opacity: 1,
				duration: 0.01,
			})

			// Then animate lines
			tl.to(splitText.lines, {
				y: 0,
				autoAlpha: 1,
				duration: options.duration,
				ease: options.ease,
				stagger: options.stagger,
				delay: options.delay,
			})
		} else {
			// Handle child elements as before
			const children = [...target.children]
			gsap.set(children, { autoAlpha: 0, y: 24 })

			// Make sure target is visible but transparent
			gsap.set(target, { visibility: 'visible', opacity: 0 })

			// Create timeline for better control
			const tl = gsap.timeline()

			// Fade in target first
			tl.to(target, {
				opacity: 1,
				duration: 0.01,
			})

			// Then animate children
			tl.to(children, {
				autoAlpha: 1,
				y: 0,
				duration: options.duration,
				ease: options.ease,
				stagger: options.stagger,
				delay: options.delay,
			})
		}
	},

	fadeRight(target, options) {
		gsap.fromTo(
			target,
			{ autoAlpha: 0, x: -24 },
			{
				autoAlpha: 1,
				x: 0,
				duration: options.duration,
				ease: options.ease,
				delay: options.delay,
			},
		)
	},
})
