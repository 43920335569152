import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(ScrollTrigger, SplitText)

class GSAPAnimations {
	constructor(options = {}) {
		this.defaultDuration = options.duration || 0.8
		this.defaultEase = options.ease || 'quart.out'
		this.defaultStagger = options.stagger || 0.25
	}

	animate(elements, animationType, options = {}) {
		const animationProps = {
			duration: options.duration || this.defaultDuration,
			ease: options.ease || this.defaultEase,
			stagger: options.stagger || this.defaultStagger,
			scrollTrigger: options.scrollTrigger || {},
			splitText: options.splitText || { type: 'lines' },
			...options,
		}

		switch (animationType) {
			case 'fadeUp':
				return this.fadeUp(elements, animationProps)
			case 'fadeDown':
				return this.fadeDown(elements, animationProps)
			case 'slideUp':
				return this.slideUp(elements, animationProps)
			case 'slideDown':
				return this.slideDown(elements, animationProps)
			default:
				console.warn(`Animation type '${animationType}' not recognized.`)
				return null
		}
	}

	applyAnimation(elements, fromVars, toVars, options) {
		if (elements == '.slide-up') {
			this.wrapElementsWithMask(elements)
		}
		let targets = gsap.utils.toArray(elements)

		if (targets.length === 0) {
			console.warn('No valid targets found for animation')
			return
		}

		targets.forEach((target) => {
			const delay = target.dataset.delay ? parseFloat(target.dataset.delay) : 0
			gsap.fromTo(
				target,
				{ ...fromVars },
				{
					...toVars,
					delay,
					scrollTrigger: options.scrollTrigger,
					ease: options.ease || this.defaultEase,
					duration: options.duration || this.defaultDuration,
				},
			)
		})
	}

	onEnter() {
		// Adjust this value as needed
		return '50% 80%'
	}

	wrapElementsWithMask(elements) {
		const elementsArray = gsap.utils.toArray(elements)
		elementsArray.forEach((element) => {
			if (!element.parentNode.classList.contains('mask')) {
				const mask = document.createElement('div')
				mask.classList.add('mask')
				element.parentNode.insertBefore(mask, element)
				mask.appendChild(element)
			}
		})
	}

	fadeUp(elements, options) {
		this.applyAnimation(elements, { autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, options)
	}

	fadeDown(elements, options) {
		this.applyAnimation(elements, { y: -24, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, options)
	}

	slideUp(elements, options) {
		this.applyAnimation(elements, { autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, options)
	}

	slideDown(elements, options) {
		this.applyAnimation(elements, { y: -24, autoAlpha: 0 }, { y: 0, autoAlpha: 1 }, options)
	}

	createHorizontalScroll(container, options = {}) {
		console.log('Creating horizontal scroll for container:', container)

		const scrollItems = gsap.utils.toArray(container.querySelectorAll('.horizontal-scroll-item'))
		console.log('Found scroll items:', scrollItems)

		if (scrollItems.length === 0) {
			console.warn('No horizontal scroll items found in container')
			return
		}

		// Create entrance animation timeline
		const entranceTl = gsap.timeline({
			scrollTrigger: {
				trigger: container,
				start: 'top 80%',
				onEnter: () => entranceTl.play(),
			},
		})

		// Add items to entrance timeline with stagger
		entranceTl.to(scrollItems, {
			autoAlpha: 1,
			y: 0,
			duration: 1,
			stagger: 0.2,
			ease: 'power3.out',
		})

		const totalWidth = scrollItems.reduce((acc, el) => acc + el.offsetWidth * 1.1, 0)
		console.log('Total width of scroll items:', totalWidth)

		// Create horizontal scroll animation
		gsap.to(scrollItems, {
			x: () => -(totalWidth - container.offsetWidth),
			ease: 'none',
			scrollTrigger: {
				trigger: container,
				start: 'top top',
				end: () => `+=${totalWidth - container.offsetWidth}`,
				scrub: options.scrub || 1,
				pin: true,
				anticipatePin: 1,
				invalidateOnRefresh: true,
				pinSpacing: true,
				pinType: 'fixed',
				fastScrollEnd: false,
				...options.scrollTrigger,
			},
		})
	}

	buttonRollover(buttons) {
		gsap.utils.toArray(buttons).forEach((button) => {
			const buttonText = button.querySelector('.button-text')
			if (!buttonText) {
				console.warn('Button rollover effect requires a span with class "button-text"')
				return
			}

			if (buttonText.getAttribute('data-text') === null) {
				buttonText.setAttribute('data-text', buttonText.textContent)
			}

			const buttonTextAfter = button.querySelector('.button-text::after')
			gsap.set(buttonTextAfter, { autoAlpha: 0 })
			gsap.set(buttonText, { autoAlpha: 1 })

			const tl = gsap.timeline({ paused: true })

			tl.to(buttonText, {
				yPercent: -100,
				duration: 0.4,
				ease: 'power2.inOut',
			})

			button.addEventListener('mouseenter', () => tl.play())
			button.addEventListener('mouseleave', () => tl.reverse())
		})
	}

	revertSplitText(elements) {
		const elementsArray = gsap.utils.toArray(elements)
		elementsArray.forEach((element) => {
			const splitLines = element.querySelectorAll('.split-line')
			splitLines.forEach((line) => {
				line.replaceWith(...line.childNodes)
			})
		})
	}

	curtainHover(elements) {
		gsap.utils.toArray(elements).forEach((element) => {
			let curtainColor =
				element.closest('section')?.getAttribute('data-bg') || element.getAttribute('data-color') || 'inherit'
			const curtain = document.createElement('div')
			const trigger = element.parentNode.querySelector('.curtain-hover-trigger')
			curtain.classList.add('curtain-hover-overlay')
			element.parentNode.appendChild(curtain)

			gsap.set(curtain, {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				width: '100%',
				height: '100%',
				backgroundColor: curtainColor,
				mixBlendMode: 'multiply',
				visibility: 'hidden',
				zIndex: 5,
				pointerEvents: 'none',
			})

			gsap.set(curtain.parentElement, {
				overflow: 'hidden',
			})

			gsap.set(element, {
				zIndex: 1,
			})

			const tl = gsap.timeline({ paused: true })

			tl.fromTo(
				curtain,
				{
					y: '100%',
					height: '100%',
				},
				{
					visibility: 'visible',
					height: '100%',
					y: '0%',
					ease: 'quart.out',
					duration: 1,
				},
				'>-25%',
			)

			element.addEventListener('mouseenter', () => tl.play())
			element.addEventListener('mouseleave', () => tl.reverse())
			if (trigger) {
				trigger.addEventListener('mouseleave', () => tl.reverse())
				trigger.addEventListener('mouseenter', () => tl.play())
			}
		})
	}
}

export const gsapAnimations = new GSAPAnimations()

// Export individual animation functions
export const fadeUp = gsapAnimations.fadeUp.bind(gsapAnimations)
export const fadeDown = gsapAnimations.fadeDown.bind(gsapAnimations)
export const slideUp = gsapAnimations.slideUp.bind(gsapAnimations)
export const slideDown = gsapAnimations.slideDown.bind(gsapAnimations)
export const createHorizontalScroll = gsapAnimations.createHorizontalScroll.bind(gsapAnimations)
export const buttonRollover = gsapAnimations.buttonRollover.bind(gsapAnimations)
export const curtainHover = gsapAnimations.curtainHover.bind(gsapAnimations)
