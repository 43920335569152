import { gsap } from 'gsap'
import { initializeHeadroom } from './headroom-init'
import { reinitializeAlpine } from './alpine-init'

// Helper function to handle post-preloader tasks
function handlePreloaderComplete(callback) {
	const preloader = document.getElementById('preloader')
	const siteContent = document.getElementById('site-content')
	const header = document.querySelector('.header')

	console.log('handlePreloaderComplete called')

	// First make content visible
	if (siteContent) {
		siteContent.classList.remove('content__main--hidden')
	}
	if (header) {
		header.classList.add('headroom--pinned')
	}

	// Execute callback if provided
	if (typeof callback === 'function') {
		callback()
	}

	// Hide preloader
	if (preloader) {
		console.log('Setting preloader display to none')
		preloader.style.display = 'none'
	}

	// Initialize headroom
	initializeHeadroom()

	// Reset all intersect elements to hidden state
	document.querySelectorAll('[x-intersect]').forEach(el => {
		gsap.set(el, { opacity: 0, y: 24 })
	})

	// Reinitialize Alpine.js
	setTimeout(() => {
		reinitializeAlpine()
		// Trigger scroll event after reinitialization
		setTimeout(() => {
			window.dispatchEvent(new Event('scroll'))
		}, 100)
	}, 100)
}

// Helper function to reinitialize animations
function reinitializeAnimations() {
	console.log('Reinitializing animations')
	// Get all hero animation elements
	const heroElements = document.querySelectorAll('.hero__tagline, .hero__heading, .hero__subheading')
	heroElements.forEach(el => {
		// Reset the element's position
		gsap.set(el, { clearProps: 'all' })
		// Apply initial animation state
		gsap.set(el, { opacity: 0, y: 24 })
		// Animate in
		gsap.to(el, {
			opacity: 1,
			y: 0,
			duration: 1,
			ease: 'power3.out'
		})
	})

	// Force scroll event after hero animations
	setTimeout(() => {
		window.dispatchEvent(new Event('scroll'))
	}, 1000)
}

// Check preloader state immediately when script loads
const preloader = document.getElementById('preloader')
if (preloader && sessionStorage.getItem('preloader')) {
	console.log('Preloader exists in sessionStorage, skipping animation')
	handlePreloaderComplete()
	// Add a small delay to ensure content is visible
	setTimeout(reinitializeAnimations, 100)
}

export function initializePreloader(callback) {
	const preloader = document.getElementById('preloader')
	const siteContent = document.getElementById('site-content')
	const header = document.querySelector('.header')
	const heroImage = document.querySelector('.hero__image')

	if (!preloader || !siteContent) return

	if (sessionStorage.getItem('preloader')) {
		console.log('Preloader exists in sessionStorage')
		handlePreloaderComplete(callback)
		// Add a small delay to ensure content is visible
		setTimeout(reinitializeAnimations, 100)
		return
	}

	console.log('Starting preloader animation')
	const background = preloader.querySelector('.preloader__background')
	const logo = preloader.querySelector('svg')
	logo.classList.remove('invisible')
	if (!background || !logo) return

	// Set initial states
	gsap.set(logo, { yPercent: 100, autoAlpha: 0 })
	gsap.set(siteContent, { autoAlpha: 0 })

	// Create a GSAP timeline to sequence animations
	const tl = gsap.timeline({
		onComplete: () => {
			console.log('Preloader animation complete')
			gsap.to(siteContent, {
				autoAlpha: 1,
				duration: 0.5,
				onComplete: () => {
					console.log('Content fade in complete')
					handlePreloaderComplete(callback)
					sessionStorage.setItem('preloader', 'true')
					// Reinitialize animations after everything is done
					setTimeout(reinitializeAnimations, 100)
				},
			})
		},
	})

	tl.to(background, { scaleY: 1, duration: 0.5, ease: 'power2.out' }, 0)
		.to(logo, { yPercent: 0, autoAlpha: 1, duration: 0.5, ease: 'power2.out' }, 0.5)
		.to(logo, { yPercent: -100, autoAlpha: 0, duration: 0.5, ease: 'power2.in' }, 1.5)
		.to(background, { scaleY: 0, transformOrigin: 'top', duration: 0.5, ease: 'power2.in' }, 2)
}
