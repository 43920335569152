import { ofetch } from 'ofetch'

const baseApiUrl = '/api'
const baseQueryUrl = '/graphql'

const addToken = (options) => {
	if (document.querySelector('meta[name="csrf-token"]')?.content) {
		options.headers['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').content
	}
	if (window?.livewireScriptConfig?.csrf) {
		options.headers['X-CSRF-TOKEN'] = window.livewireScriptConfig.csrf
	}
}

const addQuery = (options, query) => {
	if (Object.keys(query).length > 0) {
		options.query = query
	}
}

const addBody = (options, body, asQuery) => {
	if (Object.keys(body).length > 0) {
		options.body = !asQuery ? body : { query: body }
	}
}

const addBaseUrl = (options, url) => {
	if (!url.startsWith('http') && !options?.noBaseUrl) {
		options.baseURL = baseApiUrl
	}
}

const $fetch = {
	exists: async function (url, options = {}) {
		options.method = 'HEAD'
		let responseError = null
		const response = await ofetch(url, options).catch((error) => {
			responseError = error
		})

		return !responseError
	},

	get: async function (url, query = {}, options = {}) {
		options.headers = {
			...options.headers,
		}
		options.method = 'GET'
		addBaseUrl(options, url)
		addToken(options)
		addQuery(options, query)
		let responseError = null
		const response = await ofetch(url, options).catch((error) => {
			responseError = error
		})

		return { data: response, error: responseError, ok: responseError === null }
	},

	post: async function (url, query = {}, body = {}, options = {}) {
		options.headers = {
			...options.headers,
		}
		options.method = 'POST'
		addBaseUrl(options, url)
		addToken(options)
		addQuery(options, query)
		addBody(options, body)
		let responseError = null
		const response = await ofetch(url, options).catch((error) => {
			responseError = error
		})

		return { data: response, error: responseError, ok: responseError === null }
	},

	postform: async function (url, query = {}, formData = {}, options = {}) {
		options.headers = {
			...options.headers,
		}
		addBaseUrl(options, url)
		addToken(options)
		addQuery(options, query)
		return new Promise((resolve) => {
			const xhr = new XMLHttpRequest()
			xhr.open('POST', options.baseURL ? options.baseURL + url : url, true)
			let event = options.event
			xhr.upload.onprogress = (event) => {
				if (event.lengthComputable && options?.control) {
					options.control.updateUploadProgress(Math.round((event.loaded / event.total) * 100))
				}
			}
			xhr.onload = () => {
				let data = null,
					error = null,
					ok = false
				if (xhr.status === 200) {
					data = JSON.parse(xhr.responseText)
					ok = true
				} else {
					data = JSON.parse(xhr.responseText)
					error = { message: data?.message ?? 'An error occurred while uploading files.' }
				}
				resolve({ data, error, ok })
			}
			xhr.onerror = () => {
				let error = { message: 'An error occurred while uploading files.' }
				resolve({ data: null, error, ok: false })
			}
			xhr.setRequestHeader('X-CSRF-TOKEN', options.headers['X-CSRF-TOKEN'])
			xhr.send(formData)
		})
	},

	query: async function (query, options = {}) {
		options.headers = {
			...options.headers,
		}
		options.method = 'POST'
		addToken(options)
		addBody(options, query, true)
		let responseError = null
		const response = await ofetch(baseQueryUrl, options).catch((error) => {
			responseError = error
		})

		return { data: response, error: responseError, ok: responseError === null }
	},
}

export default $fetch
