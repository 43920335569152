export default () => ({
	activeModal: null,

	toggle() {
		this.activeModal = !this.activeModal
		this.toggleScroll()
	},

	close() {
		this.activeModal = null
		this.toggleScroll()
	},

	toggleScroll() {
		if (this.activeModal) {
			document.documentElement.style.overflow = 'hidden'
		} else {
			document.documentElement.style.overflow = 'initial'
		}
	},
})
