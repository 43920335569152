import Lenis from '@studio-freight/lenis'

let lenis

export function initializeLenis() {
	lenis = new Lenis({
		prevent: (node) => node.classList.contains('header'),
	})

	lenis.on('scroll', () => {
		if (window.ScrollTrigger) {
			window.ScrollTrigger.update()
		}
	})

	function raf(time) {
		lenis.raf(time)
		requestAnimationFrame(raf)
	}

	requestAnimationFrame(raf)
}

export function getLenis() {
	return lenis
}
