import { gsap } from 'gsap'

export default () => ({
	isOpen: false,
	closeOthers: true,
	selectedFilters: [],

	init() {
		this.closeOthers = this.$el.dataset.closeOthers !== 'false'
		// Listen for custom event to close accordion
		this.$el.addEventListener('close-accordion', () => {
			if (this.isOpen) {
				this.isOpen = false
				this.close()
			}
		})
	},

	getItemsCount() {
		return this.$refs?.items?.querySelectorAll('.accordion__item.item-normal').length ?? 0
	},

	toggle() {
		if (!this.isOpen && this.closeOthers) {
			// Dispatch close event to all other accordions
			document.querySelectorAll('[x-data="accordion()"]').forEach((accordion) => {
				if (accordion !== this.$el) {
					accordion.dispatchEvent(new CustomEvent('close-accordion'))
				}
			})
		}

		if (!this.isOpen) {
			this.open()
		} else {
			this.close()
		}

		this.isOpen = !this.isOpen
	},

	open() {
		const content = this.$refs.content
		const items = this.$refs.items

		gsap.set(content, { display: 'block', height: 'auto' })
		const height = content.offsetHeight

		gsap.fromTo(content, { height: 0, opacity: 0 }, { height: height, opacity: 1, duration: 0.3, ease: 'power2.inOut' })

		gsap.fromTo(
			items.children,
			{ opacity: 0, y: 20 },
			{ opacity: 1, y: 0, duration: 0.4, stagger: 0.05, ease: 'power2.out', delay: 0.1 },
		)
	},

	close() {
		const content = this.$refs.content
		const items = this.$refs.items

		gsap.to(items.children, {
			opacity: 0,
			y: 20,
			duration: 0.3,
			stagger: 0.05,
			ease: 'power2.in',
		})

		gsap.to(content, {
			height: 0,
			opacity: 0,
			duration: 0.3,
			ease: 'power2.in',
			delay: 0.1,
			onComplete: () => {
				gsap.set(content, { display: 'none' })
			},
		})
	},

	selectItem(item) {
		if (item === 'all') {
			this.selectedFilters = ['all']
			return
		}
		this.selectedFilters = this.selectedFilters.filter((filter) => filter !== 'all')
		const index = this.selectedFilters.indexOf(item)
		if (index === -1) {
			this.selectedFilters.push(item)
		} else {
			this.selectedFilters.splice(index, 1)
		}
		if (this.selectedFilters.length === this.getItemsCount()) {
			this.selectedFilters = ['all']
		}
		if (this.selectedFilters.length === 0) {
			this.selectedFilters = ['all']
		}
		this.$dispatch('filter-changed', { filter: this })
	},
})
